
import { Component } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HospitalReservationForm from "@/components/forms/HospitalReservationForm.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import ReservationModule from "@/store/reservation";
import HospitalModule from "@/store/hospital";
import FacilityService from "@/services/facilities";
import moment from "moment";
@Component({
  name: "HospitalReservation",
  components: {
    Header,
    Footer,
    HospitalReservationForm,
  },
})
export default class HospitalReservation extends CheckCommonLogin {
  private get selectedCourse() {
    return ReservationModule.selectedCourse;
  }

  private get selectedDate() {
    return ReservationModule.selectedDate;
  }

  private get hospitalId() {
    return HospitalModule.hospitalId;
  }

  private get selectedNumberOfPets() {
    return ReservationModule.selectedNumberOfPets;
  }

  private get startTime() {
    const weekdaysShort = ["日", "月", "火", "水", "木", "金", "土"];

    const formatDate = moment(this.selectedDate);
    const m = formatDate.month() + 1;
    const d = formatDate.date();
    const day = formatDate.day();
    const h = formatDate.format("HH");
    const minute = formatDate.format("mm");
    return `${m}/${d} (${weekdaysShort[day]}) ${h}:${minute} 〜`;
  }

  private hospitalData = null;

  private mounted() {
    this.getHospitalData();
  }

  private async getHospitalData() {
    const res = await FacilityService.getHospital(this.hospitalId);
    this.hospitalData = res.data.data[0];
  }
}
