import moment from "moment";

export const PetSex = ["オス", "メス", "オス去勢", "メス避妊", "不明", "その他"];

export const Months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const getMonthsFromYear = function (year: string | number, pastOnly = false): string[] {
  if (pastOnly) {
    const today = new Date();
    if (year == today.getFullYear()) {
      return Months.slice(0, today.getMonth() + 1);
    }
  }
  return Months;
};

export const getDaysFromMonth = function (year: string | number, month: string | number, pastOnly = false): string[] {
  const days = Array.from({ length: moment(`${year}${month}`, "YYYYMM").daysInMonth() }, (_, k) =>
    `${k + 1}`.padStart(2, "0")
  );
  if (pastOnly) {
    const today = new Date();
    if (year == today.getFullYear() && parseInt(`${month}`) == today.getMonth() + 1) {
      return days.slice(0, today.getDate());
    }
  }
  return days;
};

export const PetTypeDescriptionItems = {
  犬: [
    "Mix",
    "アーフェンピンシャー",
    "アイリッシュ・ウルフハウンド",
    "アイリッシュ・セター",
    "アイリッシュ・テリア",
    "アフガン・ハウンド",
    "アメリカン・コッカー・スパニエル",
    "アメリカン・スタッフォードシャー・テリア",
    "アメリカン・ピット・ブル・テリア",
    "アメリカン・ブルドッグ",
    "アラスカン・マラミュート",
    "イタリアン・グレーハウンド",
    "イングリッシュ・コッカー・スパニエル",
    "イングリッシュ・スプリンガー・スパニエル",
    "イングリッシュ・セター",
    "イングリッシュ・トイ・テリア",
    "イングリッシュ・ポインター",
    "ウィペット",
    "ウエスト・ハイランド・ホワイト・テリア",
    "ウェルシュ・コーギー・カーディガン",
    "ウェルシュ・コーギー・ペンブローク",
    "ウェルシュ・スプリンガー・スパニエル",
    "ウェルシュ・テリア",
    "エアデール・テリア",
    "エスキモー犬",
    "オーストラリアン・キャトル・ドッグ",
    "オーストラリアン・ケルピー",
    "オーストラリアン・シェパード",
    "オーストラリアン・シルキー・テリア",
    "オーストラリアン・テリア",
    "オールド・イングリッシュ・シープドッグ",
    "キースホンド",
    "キャバリア・キング・チャールズ・スパニエル",
    "キング・チャールズ・スパニエル",
    "クランバー・スパニエル",
    "グレート・スイス・マウンテンドッグ",
    "グレート・デーン",
    "グレート・ピレニーズ",
    "グレーハウンド",
    "ケアーン・テリア",
    "ケリー・ブルー・テリア",
    "コーイケルホンディエ",
    "ゴードン・セター",
    "ゴールデン・レトリーバー",
    "コリア・ジンドー・ドッグ",
    "コリー（スムース）",
    "コリー（ラフ）",
    "サモエド",
    "サルーキ",
    "シー・ズー",
    "シーリハム・テリア",
    "シェットランド・シープドッグ",
    "シベリアン・ハスキー",
    "シャー・ペイ",
    "ジャーマン・シェパード・ドッグ",
    "ジャーマン・スパニエル",
    "ジャーマン・スピッツ・ミッテル",
    "ジャーマン・ポインター",
    "ジャイアント・シュナウザー",
    "ジャック・ラッセル・テリア",
    "スカイ・テリア",
    "スキッパーキ",
    "スコティッシュ・テリア",
    "スタッフォードシャー・ブル・テリア",
    "スタンダード・シュナウザー",
    "スパニッシュ・ウォーター・ドッグ",
    "スムース・フォックス・テリア",
    "セント・バーナード",
    "ダックスフンド（カニーンヘン）",
    "ダックスフンド（スタンダード）",
    "ダックスフンド（ミニチュア）",
    "ダッチ・シェパード・ドッグ",
    "ダルメシアン",
    "ダンディ・ディンモント・テリア",
    "チェサピーク・ベイ・レトリーバー",
    "チベタン・スパニエル",
    "チベタン・テリア",
    "チベタン・マスティフ",
    "チャイニーズ・クレステッド・ドッグ",
    "チャウ・チャウ",
    "チワワ",
    "ディンゴ",
    "トイ・フォックス・テリア",
    "トイ・マンチェスター・テリア",
    "ドーベルマン",
    "ナポリタン・マスティフ",
    "ニューファンドランド",
    "ノーフォーク・テリア",
    "ノーリッチ・テリア",
    "ノルウェジアン・エルクハウンド",
    "バーニーズ・マウンテン・ドッグ",
    "パグ",
    "バセット・ハウンド",
    "バセンジー",
    "パピヨン",
    "ビアデッド・コリー",
    "ビーグル",
    "ビション・フリーゼ",
    "ピレニアン・マスティフ",
    "ファラオ・ハウンド",
    "プードル（スタンダード）",
    "プードル（トイ）",
    "プードル（ミディアム）",
    "プードル（ミニチュア）",
    "ブービエ・デ・フランダース",
    "プーリー",
    "プチ・バセット・グリフォン・バンデーン",
    "プチ・ブラバンソン",
    "フラットコーテッド・レトリーバー",
    "ブラッドハウンド",
    "ブリアード",
    "ブリタニー・スパニエル",
    "ブリュッセル・グリフォン",
    "ブル・テリア",
    "ブルドッグ",
    "ブルマスティフ",
    "フレンチ・ブルドッグ",
    "ペキニーズ",
    "ベドリントン・テリア",
    "ベルジアン・シェパード・ドッグ（グローネンダール）",
    "ベルジアン・シェパード・ドッグ（タービュレン）",
    "ベルジアン・シェパード・ドッグ（マリノア）",
    "ベルジアン・シェパード・ドッグ（ラケノア）",
    "ボーダー・コリー",
    "ボーダー・テリア",
    "ポーチュギーズ・ウォーター・ドッグ",
    "ボクサー",
    "ボストン・テリア",
    "ポメラニアン",
    "ポリッシュ・ローランド・シープドッグ",
    "ボルゾイ",
    "ボルドー・マスティフ",
    "ボロニーズ",
    "マスティフ",
    "マルチーズ",
    "マンチェスター・テリア",
    "ミニチュア・シュナウザー",
    "ミニチュア・ピンシャー",
    "ミニチュア・ブル・テリア",
    "メキシカン・へアレス・ドッグ（スタンダード）",
    "メキシカン・へアレス・ドッグ（ミディアム）",
    "メキシカン・へアレス・ドッグ（ミニチュア）",
    "ヨークシャー・テリア",
    "ラサ・アプソ",
    "ラブラドール・レトリーバー",
    "レークランド・テリア",
    "レオンベルガー",
    "ローシェン",
    "ローデシアン・リッジバック",
    "ロットワイラー",
    "ワイアー・フォックス・テリア",
    "ワイマラナー",
    "紀州",
    "甲斐",
    "四国",
    "柴",
    "秋田",
    "土佐",
    "日本スピッツ",
    "日本テリア",
    "北海道",
    "琉球",
    "狆",
  ],
  猫: [
    "Mix",
    "アビシニアン",
    "アメリカン・カール",
    "アメリカン・ショートヘア",
    "アメリカン・ボブテイル",
    "アメリカン・ワイヤーヘア",
    "エキゾチック",
    "エキゾチック・ショートヘア",
    "エジプシャン・マウ",
    "オシキャット",
    "オリエンタル・ショートヘア",
    "オリエンタル・ロングヘア",
    "カラーポイント・ショートヘア",
    "キムリック",
    "クリル・アイランド・ボブテイル",
    "コーニッシュ・レックス",
    "コラット",
    "サイベリアン",
    "ジャバニーズ",
    "ジャパニーズ・ボブテイル",
    "シャム",
    "シャルトリュー",
    "シンガプーラ",
    "スコティッシュ・フォールド",
    "スノーシュー",
    "スフィンクス",
    "スポッテッド・ミスト",
    "セルカーク・レックス",
    "ソマリ",
    "ターキッシュ・アンゴラ",
    "ターキッシュ・バン",
    "チャウシー",
    "デボン・レックス",
    "トイガー",
    "トンキニーズ",
    "ネーベロング",
    "ノルウェージャン・フォレスト・キャット",
    "バーマン",
    "バーミーズ",
    "ハバナ",
    "ハバナ・ブラウン",
    "バリニーズ",
    "ピーターボールド",
    "ピクシーボブ",
    "ヒマラヤン",
    "ブリティッシュ・ショートヘアー",
    "ブリティッシュ・ロングヘア",
    "ペルシャ",
    "ペルシャ（チンチラ）",
    "ベンガル",
    "ボンベイ",
    "マンクス",
    "マンチカン",
    "メイン・クーン",
    "ヨーロピアン・ショートヘアー",
    "ヨーロピアン・バーミーズ",
    "ラ・パーマ",
    "ラガマフィン",
    "ラグドール",
    "ロシアンブルー",
    "日本猫",
  ],
  鳥: [
    "アオボウシインコ",
    "アオメキバタン",
    "アカクサインコ",
    "アキクサインコ",
    "アケボノインコ",
    "アヒル",
    "ウコッケイ",
    "ウズラ",
    "ウロコメキシコ",
    "オオバタン",
    "オオハナインコ",
    "オカメインコ",
    "オキナインコ",
    "カエデチョウ",
    "カゲロウチョウ",
    "カナリア",
    "キソデボウシインコ",
    "キバタン",
    "キビタイボウシインコ",
    "キュウカンチョウ",
    "キンカチョウ",
    "ギンパラ",
    "クルマサカオウム",
    "コガネメキシコ",
    "コキンチョウ",
    "コザクラインコ",
    "ゴシキセイガイインコ",
    "コバタン",
    "コミドリコンゴウインコ",
    "サザナミインコ",
    "サトウチョウ",
    "シモフリインコ",
    "シャモ",
    "ジュウシマツ",
    "ショウジョウインコ",
    "シロハラインコ",
    "スズメ",
    "スミインコ",
    "スミレコンゴウインコ",
    "セキセイインコ",
    "ソウシチョウ",
    "ソロモンオウム",
    "タイハクオウム",
    "ダルマインコ",
    "ネズミガシラハネナガインコ",
    "ビセイインコ",
    "ヒメコンゴウインコ",
    "ブンチョウ",
    "ベニコンゴウインコ",
    "ボタンインコ",
    "ミドリコンゴウインコ",
    "メジロ",
    "モモイロインコ",
    "ヨウム",
    "ルリコンゴウインコ",
    "ワキコガネイロウロコインコ",
  ],
  モルモット: ["モルモット"],
  フェレット: ["フェレット"],
  ハムスター: ["キャンベル", "ゴールデン", "ジャンガリアン", "チャイニーズ", "ロボロフスキー"],
  カメ: ["カメ"],
  ウサギ: [
    "Mix",
    "アメリカンファジーロップ",
    "イングリッシュアンゴラ",
    "イングリッシュロップ",
    "サテン",
    "ジャージーウーリー",
    "ダッチ",
    "タン",
    "チェッカードジャイアント",
    "ドワーフホト",
    "ドワーフロップ",
    "ニュージーランド",
    "ネザーランドドワーフ",
    "バニファドワーフ",
    "バニファライオン",
    "ヒマラヤン",
    "ブリタニア・ペチィーテ",
    "フレミッシュジャイアント",
    "フレンチロップ",
    "ベルジアンヘアー",
    "ベルベッティンロップ",
    "ホーランドロップ",
    "ポーリッシュ",
    "ミニウサギ",
    "ミニサテン",
    "ミニレッキス",
    "ミニロップ",
    "ライオン",
    "ライオンドワーフ",
    "ライオンロップ",
    "レッキス",
    "ロップイヤー",
    "白うさぎ",
  ],
  その他: ["その他"],
};
