
import { Component, Vue, Watch } from "vue-property-decorator";
import ReservationModule from "@/store/reservation";
import HospitalModule from "@/store/hospital";
import AuthModule from "@/store/auth";
import MemberService from "@/services/members";
import FacilityService from "@/services/facilities";
import { ReservationInfo, PetForm, PetData, HospitalData, KeeperAnimalData } from "@/@types/reservation";
import { PetSex, getMonthsFromYear, getDaysFromMonth, PetTypeDescriptionItems } from "@/constants";
import { getPhoneNumber3, getPostalCode2 } from "@/utils/Util";
import _ from "lodash";
import { Keeper } from "@/@types/auth";

@Component
export default class HospitalReservationForm extends Vue {
  private get reservationInfo() {
    return ReservationModule.reservationInfo;
  }
  private get hospitalId() {
    return HospitalModule.hospitalId;
  }
  private get selectedMyPets() {
    return ReservationModule.selectedMyPets;
  }
  private get selectedNumberOfPets() {
    return ReservationModule.selectedNumberOfPets;
  }
  private get reReservationPetIds() {
    return ReservationModule.reReservationPetIds;
  }

  private get me() {
    return AuthModule.me;
  }

  private isCheckedPet(pet: KeeperAnimalData) {
    return this.checkedMyPets.some((p) => p.animalId === pet.animalId);
  }

  private get isMaxSelectedPets() {
    if (this.myPets.length) {
      return this.checkedMyPets.length + this.reservationInfo.pets.length >= this.selectedNumberOfPets;
    } else {
      return this.reservationInfo.pets.length >= this.selectedNumberOfPets;
    }
  }

  private keeper: Keeper | null = null;

  private checkedMyPets: KeeperAnimalData[] = [];

  private myPets = [];

  private animalTypes: string[] = [
    "犬",
    "猫",
    "鳥",
    "ウサギ",
    "フェレット",
    "ハムスター",
    "モルモット",
    "カメ",
    "その他",
  ];

  private addPetForm = false;

  private showLabel = false;

  private hospitalInfo: HospitalData | null = null;

  private async getHospitalInfo() {
    try {
      const res = await FacilityService.getHospital(this.hospitalId);
      this.hospitalInfo = res.data.data[0];
    } catch (error: any) {
      throw new Error(error);
    }
  }

  // private async getAnimalTypes() {
  //   try {
  //     const res = await FacilityService.getAnimalTypes();
  //     this.animalTypes = res.data.data;
  //     const otherIndex = this.animalTypes.findIndex((item) => item.animal_type === "その他");
  //     this.animalTypes.push(this.animalTypes.splice(otherIndex, 1)[0]);
  //   } catch (error: any) {
  //     throw new Error(error);
  //   }
  // }

  private zipValidateOn = false;
  @Watch("form.postalCode", { deep: true })
  private changeZipValidateOn() {
    this.zipValidateOn = true;
    if (!this.form.postalCode.number1 && !this.form.postalCode.number2) {
      this.zipValidateOn = false;
    }
  }

  private meFormAutoInput() {
    if (this.me && !this.form.email) this.form.email = this.me?.email;
    if (this.me && this.keeper) {
      if (!this.form.lastName) this.form.lastName = this.keeper.familyName;
      // if (!this.form.kanaLastName) this.form.kanaLastName = this.me?.last_name_kana;
      if (!this.form.firstName) this.form.firstName = this.keeper.firstName;
      // if (!this.form.kanaFirstName) this.form.kanaFirstName = this.me?.first_name_kana;
      // if (this.me.tel) this.form.phoneNumber = getPhoneNumber3(this.me.tel);
      // if (this.me.zip_code) this.form.postalCode = getPostalCode2(this.me.zip_code);
      // if (!this.form.address) this.form.address = this.me?.address;
    }
  }

  private async getKeeper() {
    try {
      if (this.me?.id) {
        const res = await MemberService.getKeeperByAnirece(this.me.id, this.hospitalId);
        if (res.data.data[0].keeper.length) {
          this.keeper = res.data.data[0].keeper[0];
          this.myPets = res.data.data[0].keeper[0].animal;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  private async mounted() {
    this.checkedMyPets = this.selectedMyPets;
    this.form = this.reservationInfo;
    this.getHospitalInfo();
    await this.getKeeper();
    this.meFormAutoInput();
    // this.getAnimalTypes();
    if (this.myPets.length == 0 && this.form.pets.length == 0) {
      this.form.pets.push(_.cloneDeep(this.defaultPetInfo));
    }
    if (this.reReservationPetIds.length) {
      const selectPets = this.myPets.filter((pet: PetData) => this.reReservationPetIds.includes(pet.animalId));
      this.checkedMyPets = selectPets;
    }
  }
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }
  private defaultPetInfo: PetForm = {
    petName: "",
    kanaPetName: "",
    petType: "",
    petSex: undefined,
    petBirthDay: {
      year: undefined,
      month: undefined,
      day: undefined,
    },
    petTypeDescription: "",
    petInsurance: "",
  };
  private form: ReservationInfo = {
    lastName: "",
    firstName: "",
    kanaLastName: "",
    kanaFirstName: "",
    email: "",
    phoneNumber: {
      number1: "",
      number2: "",
      number3: "",
    },
    postalCode: {
      number1: "",
      number2: "",
    },
    address: "",
    pets: [],
    symptoms: "",
    memo: "",
  };
  private addPetInfo() {
    this.form.pets.push(_.cloneDeep(this.defaultPetInfo));
    this.addPetForm = true;
  }
  private deletePet(index: number) {
    this.form.pets.splice(index, 1);
  }

  private submit() {
    if (!this.form.symptoms) {
      delete this.form.symptoms;
    }
    if (this.myPets.length && this.form.pets.length && !this.form.pets[0].petName) this.form.pets = [];
    if (this.form.pets.length === 0 && this.checkedMyPets.length === 0) {
      this.showModal("noPetModal");
      return;
    }
    if (this.checkedMyPets.length || this.form.pets.length) {
      ReservationModule.changeReservationInfo(this.form);
      ReservationModule.changeSelectedMyPets(this.checkedMyPets);
      if (this.keeper) {
        ReservationModule.saveKeeper(this.keeper);
      }
      window.scrollTo(0, 0);
      ReservationModule.changeReReservationPetIds([]);
      this.$router.push({ name: "ReservationConfirm" });
    } else {
      this.showLabel = true;
      setTimeout(() => {
        this.showLabel = false;
      }, 5000);
    }
  }

  private get years() {
    const years = [];
    for (let year = new Date().getFullYear(); year >= new Date().getFullYear() - 100; year--) {
      years.push(year);
    }
    return years;
  }

  private get petSex() {
    return PetSex;
  }
  private getMonths(year: string | number) {
    return getMonthsFromYear(year, true);
  }
  private getDays(year: string | number, month: string) {
    return getDaysFromMonth(year, month, true);
  }

  private get petTypeDescriptionItems() {
    return PetTypeDescriptionItems;
  }
}
